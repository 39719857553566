<template>
  <p class="flex gap-1">
    <slot name="beforePrice" />
    <span>{{ formattedPrice }}</span>
    <slot name="afterPrice" />
  </p>
</template>

<script setup lang="ts">
const props = defineProps<{
  value: number | undefined
}>()

const formattedPrice = computed<string>(() => {
  if (!props.value || Number.isNaN(props.value)) return '–'

  const roundedAmount = (+props.value).toFixed(2)
  // const roundedAmount = Math.round(props.value * 100) / 100
  const parts = roundedAmount.split('.')
  // format 1000 chunks
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '’')
  return `CHF ${parts.join('.')}`
})
</script>
